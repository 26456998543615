/* eslint-disable @nx/enforce-module-boundaries */
import {
  CommentsIcon,
  DisagreeIcon,
  HandShakeSimpleIcon,
  DisableComment,
  DisabledAgreeDisagreeIcon,
} from '@mybridge/icons';
import { Button } from '@mybridge/ui/button';
import { HStack } from '@mybridge/ui/layout';
import { useContext, useEffect } from 'react';
import { PostCommentFormContext } from 'v4/components/comment/comment-form/context';
import { PostBoxContext } from '../context';
import { PostShareMenu } from '../post-share-menu';
import { Text } from '@mybridge/ui/text';
import { useSelector } from 'react-redux';
import { Tooltip } from '@mybridge/ui';
import { ForceSignInWrapper } from 'v4/components/force-sign-in-wrapper';
import { currentProfile } from 'v4/store/actions/user-profile.actions';
import { PostCommentAvatar } from '../post-actions-avatar';
import styles from './reel-footer.module.scss';
import { useTranslator } from '@mybridge/ui/custom-hooks/useTranslation';

export const ReelBoxFooterActions = ({
  isActive,
  isReel = false,
  handleCommentClicked,
}) => {
  const {
    commentBoxVisible,
    setCommentBoxVisible,
    handleLike,
    handleDislike,
    likeLoading,
    dislikeLoading,
    post: { liked_by, dislike_by, reply_control, can_agree, can_comment, id },
    commentsAllowed,
    handleAdClicksRouting,
    setupAdd,
    commentDisable,
  } = useContext(PostBoxContext);
  const { textInputRef } = useContext(PostCommentFormContext);
  const { userProfileInfo } = useSelector((state) => state.userProfile);
  const { loggedIn } = useSelector((state) => state.user);
  const likedByMe = liked_by?.find((lb) => lb?.id === userProfileInfo?.id);
  const dislikedByMe = dislike_by?.find((lb) => lb?.id === userProfileInfo?.id);
  const { t } = useTranslator();

  const { companyPageDetail } = useSelector((state) => state.companyPage);
  const { currentProfileCommentData } = useSelector(
    (state) => state.userProfile
  );


  return (
    <ForceSignInWrapper>
      <HStack px={4} py={1} borderTop="1px solid" borderColor="brandGray.600">
        {loggedIn && (
          <HStack flex={1} justifyContent="center">
            <PostCommentAvatar
              companyDetails={currentProfileCommentData}
              post={true}
            />
          </HStack>
        )}

        <HStack flex={1} justifyContent="center">
          <Tooltip
            label={
              commentDisable
                ? ''
                : commentsAllowed
                ? ''
                : t('otherModules.comments_disabled') || 'Comments disabled by author!'
            }
          >
            <Button
              w="100%"
              className={styles.reelBtn}
              isActive={isActive}
              variant="ghost"
              onClick={(e) => {
                handleCommentClicked();
              }}
              iconSpacing={[0, 0, 2]}
              isDisabled={!commentsAllowed || !can_comment}
            >
              <CommentsIcon color={'white'} height={25} width={25} />
            </Button>
          </Tooltip>
        </HStack>
        <HStack flex={1} justifyContent="center">
          <Button
            isLoading={likeLoading}
            className={styles.reelBtn}
            onClick={handleLike}
            w="100%"
            isDisabled={!can_agree}
            variant="ghost"
            iconSpacing={[0, 0, 2]}
          >
            <HandShakeSimpleIcon
              height={25}
              width={25}
              color={likedByMe ? '#03561E' : '#36a358'}
            />
          </Button>
        </HStack>
        <HStack flex={1} justifyContent="center">
          <Button
            isLoading={dislikeLoading}
            className={styles.reelBtn}
            w="100%"
            variant="ghost"
            onClick={handleDislike}
            isDisabled={!can_agree}
            iconSpacing={[0, 0, 2]}
          >
            <DisagreeIcon
              height={25}
              width={25}
              color={dislikedByMe ? '#A30B08' : '#ed5c5a'}
            />
          </Button>
        </HStack>
        <HStack flex={1} justifyContent="center">
          <PostShareMenu isReel={isReel} />
        </HStack>
      </HStack>
    </ForceSignInWrapper>
  );
};
